exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-bio-js": () => import("./../../../src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-recording-js": () => import("./../../../src/pages/recording.js" /* webpackChunkName: "component---src-pages-recording-js" */),
  "component---src-pages-teamtraining-js": () => import("./../../../src/pages/teamtraining.js" /* webpackChunkName: "component---src-pages-teamtraining-js" */),
  "component---src-pages-unterricht-js": () => import("./../../../src/pages/unterricht.js" /* webpackChunkName: "component---src-pages-unterricht-js" */),
  "component---src-templates-blog-posts-jsx": () => import("./../../../src/templates/blog-posts.jsx" /* webpackChunkName: "component---src-templates-blog-posts-jsx" */)
}

