import React, { useState } from 'react';
import { Link } from 'gatsby';
import '../style/Header.scss';
//import { ThemeToggler } from 'gatsby-plugin-dark-mode';
//import { VscColorMode } from 'react-icons/vsc';
import Logo from '../images/DrumUP_Logo.svg';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Divider from '@mui/material/Divider';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { SidebarData } from './SidebarData';

import DarkModeToggle from './DarkModeToggle';

const Header = () => {
  const [open, setOpen] = useState(false);
  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  const activeStyles = {
    color: 'hsl(12, 100%, 42%)',
  };

  return (
    <header className="header">
      <div className="mobileNav">
        <Link to="/">
          <img width="100px" src={Logo} alt="Logo" className="logo" />
        </Link>
        <SwipeableDrawer
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          anchor="right"
        >
          <div>
            {' '}
            <IconButton onClick={() => setOpen(false)}>
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Divider />
          <Box
            sx={{
              fontSize: '2rem',
            }}
          >
            <List>
              {SidebarData.map((item, index) => (
                <ListItem key={index} className={item.className}>
                  <Link to={item.path} activeStyle={activeStyles}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </ListItem>
              ))}
            </List>
          </Box>
        </SwipeableDrawer>

        <IconButton className="NavIconButton" onClick={() => setOpen(true)}>
          <MenuIcon fontSize="large" />
        </IconButton>
      </div>
      <div className="nav">
        <Link to="/">
          <img width="100px" src={Logo} alt="Logo" className="logo" />
        </Link>
        <div className="nav-wrapper">
          <div className="toggle">
            <DarkModeToggle className="nav-toggle" />
          </div>
          <nav>
            <ul>
              {SidebarData.map((item, index) => {
                return (
                  <li key={index} className={item.className}>
                    <Link to={item.path} activeStyle={activeStyles}>
                      {item.icon}
                      <span>{item.title}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>

          {/*  <ThemeToggler>
          {({ theme, toggleTheme }) => (
            <label>
              <input
                hidden
                type="checkbox"
                onChange={(e) =>
                  toggleTheme(e.target.checked ? 'light' : 'dark')
                }
                checked={theme === 'light'}
              />{' '}
              <VscColorMode className="dm-switch" type="button" size="2em" />
            </label>
          )}
        </ThemeToggler> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
