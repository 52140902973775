/* eslint-disable react/prop-types */
import React from 'react';
import Container from '@mui/material/Container';

import { graphql, useStaticQuery } from 'gatsby';

import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

import Footer from './Footer';
import Header from './Header';

import ScrollButton from './ScrollButton';
import '../style/styles.scss';

const Layout = (props) => {
  const { backgroundImage } = useStaticQuery(
    graphql`
      query {
        backgroundImage: file(relativePath: { eq: "BGCG.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 3000
              webpOptions: { quality: 50 }
              quality: 50
            )
          }
        }
      }
    `
  );

  const image = getImage(backgroundImage);

  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      className="bg"
      tag="section"
      {...bgImage}
      preserveStackingContext
    >
      <div className="header-container">
        <Header />
      </div>
      <Container maxWidth="lg">
        <div>
          <main className="content center">{props.children}</main>

          <ScrollButton />
        </div>
      </Container>
      <Footer />
    </BackgroundImage>
  );
};

export default Layout;
