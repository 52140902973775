export default {
  colors: {
    red_color: 'red',
    primary_green: 'color: #3eb099;',
  },

  fonts: {
    main: 'Roboto',
    mono: 'monospace',
  },
};
