import React from 'react';
import { BiHomeSmile } from 'react-icons/bi';
import { BsFileRichtext } from 'react-icons/bs';
import { FaDrum } from 'react-icons/fa';
import { AiOutlineTeam, AiOutlineContacts } from 'react-icons/ai';
import { RiTapeLine } from 'react-icons/ri';

export const SidebarData = [
  {
    title: 'drum up',
    path: '/',
    icon: <BiHomeSmile />,
    className: 'startLink icon',
  },
  {
    title: 'Unterricht',
    path: '/unterricht',
    icon: <FaDrum />,
    className: 'unterricht  icon',
  },
  {
    title: 'Teamtraining',
    path: '/teamtraining',
    icon: <AiOutlineTeam />,
    className: 'teamtraining  icon',
  },
  {
    title: 'Deine Musik',
    path: '/recording',
    icon: <RiTapeLine />,
    className: 'recording  icon',
  },
  /* {
    title: 'Musik',
    path: '/music',
    icon: <FontAwesome.FaMusic />,
    className: 'music',
  }, */
  {
    title: 'Bio',
    path: '/bio',
    icon: <BsFileRichtext />,
    className: 'uebermich  icon',
  },
  {
    title: 'Kontakt',
    path: '/kontakt',
    icon: <AiOutlineContacts />,
    className: 'kontakt  icon',
  },
];
